import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import lang from "../../langs";
import { connect } from "react-redux";
import { loginUser } from "../../actions/auth";
import { loginValidate } from "../../validations/authValidate";
import { createNotification, pageTitle } from "../../utils/helpers";
import Document from "../layout/Document";
import Logo from "../../img/logo.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const userData = {
      username: this.state.username,
      password: this.state.password
    };

    try {
      await loginValidate(userData);
    } catch (err) {
      this.setState({ errors: err });
      createNotification(
        "error",
        _.map(err, val => {
          return val;
        }).join("\n\n\n")
      );
      return false;
    }

    this.props
      .loginUser(userData)
      .then(res => {
        createNotification("success", lang("success.login"));
      })
      .catch(err => {
        if (err && typeof err.response !== undefined) {
          const error = err.response;
          if (
            _.has(error, "status") &&
            (error.status == 400 ||
              error.status == 401 ||
              error.status == 403 ||
              error.status == 422)
          ) {
            createNotification("warning", error.data.messages);
          } else {
            createNotification("error", lang("error.server_unknown"));
          }
        } else {
          createNotification("error", lang("error.server_unknown"));
        }
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (this.state.errors) {
      if (this.state.username && this.state.errors.username) {
        this.setState({ errors: { username: null } });
      }
      if (this.state.password && this.state.errors.password) {
        this.setState({ errors: { password: null } });
      }
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <Document title={pageTitle("Login")} className="pageLogin">
        <div id="bodyLogin">
          <div className="align-self-center text-center mb-4">
            <img src={Logo} alt="Onehourloan" />
          </div>

          <div className="shadow-sm p-4 mb-5 bg-white rounded">
            <div className="col align-self-center w-auto-xs mb-3">
              <div className="text-color">
                <div className="text-uppercase text-muted text-center mb-4 text-sm">
                  Login with...
                </div>

                <form name="formLogin" noValidate>
                  <div className="form-label-group mb-4">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="username"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.username
                      })}
                      onChange={this.onChange}
                      value={this.state.username}
                    />
                    {errors.username && (
                      <div className="invalid-feedback">{errors.username}.</div>
                    )}
                  </div>

                  <div className="form-label-group mb-4">
                    <input
                      type="password"
                      name="password"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.password
                      })}
                      placeholder="Password"
                      onChange={this.onChange}
                      value={this.state.password}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}.</div>
                    )}
                  </div>

                  <button
                    type="button"
                    id="loginSubmit"
                    disabled={this.props.loaded ? true : false}
                    onClick={this.onSubmit}
                    className="btn btn-danger btn-block btn-lg"
                  >
                    {this.props.loaded ? "Loading..." : "Sign In"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Document>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
