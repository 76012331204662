import {
  FIND_APPLICATION,
  APPROVE_APPLICATION,
  REJECT_APPLICATION
} from "../actions/types";

const initialState = {
  appli: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FIND_APPLICATION:
      return {
        ...state,
        appli: action.payload
      };
    case APPROVE_APPLICATION:
    case REJECT_APPLICATION:
      return {
        ...state,
        appli: null
      };
    default:
      return state;
  }
}
