import React from "react";
import _ from "lodash";
import lang from "../langs";
import rest from "../utils/rest";
import config from "../config";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { NotificationManager } from "react-notifications";
import currencyjs from "currency.js";
import CryptoJS from "crypto-js";

export const setAuthToken = token => {
  if (token) {
    // Apply to every request
    rest().defaults.headers.common["Authorization"] = "bearer " + token;
  } else {
    // Delete auth header
    delete rest().defaults.headers.common["Authorization"];
  }
};

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const encrypt = text => {
  if (_.isObject(text)) {
    return CryptoJS.AES.encrypt(JSON.stringify(text), config.cipher_crypt);
  } else {
    return CryptoJS.AES.encrypt(text, config.cipher_crypt);
  }
};

export const decryptObject = crypted => {
  const bytes = CryptoJS.AES.decrypt(crypted.toString(), config.cipher_crypt);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};

export const decrypt = crypted => {
  const bytes = CryptoJS.AES.decrypt(crypted.toString(), config.cipher_crypt);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
};

export const createNotification = (type, content) => {
  switch (type) {
    case "info":
      NotificationManager.info(content, "Info");
      break;
    case "success":
      NotificationManager.success(content, "Great!");
      break;
    case "warning":
      NotificationManager.warning(content, "Warning message");
      break;
    case "error":
      NotificationManager.error(content, "Error message");
      break;
  }
};

export const currency = number => {
  return currencyjs(number, {
    separator: ",",
    decimal: ".",
    precision: 0,
    symbol: "SGD "
  }).format(true);
};

export const numberCurrency = number => {
  return currencyjs(number, {
    separator: ",",
    decimal: ".",
    precision: 0,
    symbol: ""
  }).format(true);
};

export const currencyRaw = text => {
  return currencyjs(text, {
    separator: ",",
    decimal: ".",
    precision: 0
  });
};

export const price = (item, type = "sell_price", with_label = false) => {
  if (type == "sell_price" && item.on_sale) {
    return (
      <span className="price">
        <span>{currency(item.sale_price)}</span>
        <del>{currency(item.regular_price)}</del>
      </span>
    );
  }

  if (type == "buy_price") {
    return (
      <span className="capital-price">
        {with_label === true && (
          <span className="badge badge-secondary pr-1">B</span>
        )}
        <span className="pl-1">{currency(item.capital_cost)}</span>
      </span>
    );
  }

  return (
    <span className="price">
      {with_label === true && <span className="badge badge-danger">S</span>}
      <span className="pl-1">{currency(item.regular_price)}</span>
    </span>
  );
};

export const beforeRequest = () => dispatch => {
  // loading
  dispatch(showLoading("sectionBar"));
};

export const beforeResponse = () => dispatch => {
  // loading
  dispatch(hideLoading("sectionBar"));
};

export const urlExists = url => {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  http.send();
  return http.status !== 404;
};

export const validateRequired = (data, filterData) => {
  let output = {};
  filterData.map(key => {
    if (
      _.has(data, key) &&
      (data[key] === "" || data[key] === null || data[key] === undefined)
    ) {
      output[key] = lang("required." + key);
    }
  });

  return output;
};

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isSuperadmin = payload => {
  if (_.has(payload, "id")) {
    return _.toLower(payload.role) == "administrator" ||
      _.toLower(payload.role) == "manager"
      ? true
      : false;
  } else if (_.has(payload, "user")) {
    return _.toLower(payload.user.role) == "administrator" ||
      _.toLower(payload.user.role) == "manager"
      ? true
      : false;
  }

  return false;
};

export const isChasier = payload => {
  if (_.has(payload, "id")) {
    return _.toLower(payload.role) === "staff" ? true : false;
  } else if (_.has(payload, "user")) {
    return _.toLower(payload.user.role) === "staff" ? true : false;
  }

  return false;
};

export const inputNumberOnly = evt => {
  let theEvent = evt;
  let key;
  // Handle paste
  if (theEvent.type === "paste") {
    key = window.event.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  var regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

export const encapsulateErrors = data => {
  let errors = [];
  if (_.has(data, "validated")) {
    if (_.has(data, "messages") && typeof data.messages === "object") {
      return _.mapValues(data.messages, item => {
        return _.join(item, "\n");
      });
    } else if (_.has(data, "messages") && typeof data.messages === "string") {
      return data.messages;
    }

    return errors;
  }

  return data;
};

export const showErrors = data => {
  let errors = "";

  if (_.has(data, "messages") && typeof data.messages === "object") {
    let combines = _.mapValues(data.messages, item => {
      return _.join(item, "\n");
    });

    _.map(combines, item => {
      errors += item;
    });

    return errors;
  } else if (_.has(data, "messages") && typeof data.messages === "string") {
    return data.messages;
  }

  return "Warning error!";
};

export const findStatusText = data => status => {
  return _.findKey(data, status) ? true : false;
};

export const encodeUri = uri => {
  const str = uri.replace(" ", "%20");
  return encodeURIComponent(str);
};

export const pageTitle = title => {
  return title + " | " + config.app_title;
};

export const cacheStore = (type = "local") => {
  if (type === "local") {
    return localStorage;
  } else if (type === "session") {
    return sessionStorage;
  }
};
