import {
  FIND_APPLICATION,
  GET_ERRORS,
  REJECT_APPLICATION,
  APPROVE_APPLICATION,
  FOLLOWUP_APPLICATION
} from "./types";
import rest from "../utils/rest";

export const findApp = sn => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .get("user/form.validate", {
        params: {
          sn: sn
        }
      })
      .then(res => {
        dispatch({
          type: FIND_APPLICATION,
          payload: res.data
        });
        resolve(res);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err.response ? err.response : err);
      });
  });
};

export const rejectApp = sn => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .post("user/form.approval/reject", { sn: sn })
      .then(res => {
        dispatch({
          type: REJECT_APPLICATION,
          payload: res.data
        });
        resolve(res);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err.response ? err.response : err);
      });
  });
};

export const approveApp = sn => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .post("user/form.approval/accept", { sn: sn })
      .then(res => {
        dispatch({
          type: APPROVE_APPLICATION,
          payload: res.data
        });
        resolve(res);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err.response ? err.response : err);
      });
  });
};

export const followupApp = sn => dispatch => {
  return new Promise((resolve, reject) => {
    rest()
      .post("user/form.approval/followup", { sn: sn })
      .then(res => {
        dispatch({
          type: FOLLOWUP_APPLICATION,
          payload: res.data
        });
        resolve(res);
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response ? err.response : err
        });
        reject(err.response ? err.response : err);
      });
  });
};
