import React, { Component } from "react";

export default () => {
  return (
    <div>
      <p>
        Proin nec rutrum risus. Donec ac tincidunt dolor, faucibus fermentum
        velit. Praesent scelerisque egestas diam mattis semper. Duis at diam
        elementum lectus mollis fringilla. Etiam at lectus non sem fringilla
        egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
        dapibus justo mi, quis vulputate est tristique non. Aliquam laoreet diam
        quis purus iaculis tincidunt ut id erat.
      </p>

      <p>
        Curabitur dictum arcu in urna hendrerit consequat. Praesent eu turpis
        non turpis rutrum tristique. Pellentesque in gravida eros, non pretium
        enim. Sed ac justo quis libero feugiat fermentum id sed purus. Sed
        finibus, magna id efficitur tincidunt, erat mi fringilla nunc, sed
        lobortis ante nisl eu ex. Nullam at mi non ante consequat faucibus. Cras
        ut massa aliquet, ornare est id, cursus neque. In tempor turpis auctor
        tincidunt cursus. Maecenas ac neque vitae tellus convallis tempus non
        nec enim. Aliquam erat volutpat. Nullam scelerisque varius auctor. Nam
        euismod turpis vel imperdiet pretium. Phasellus lobortis tellus a lectus
        viverra, non aliquet ex pharetra.
      </p>

      <p>
        Suspendisse convallis at magna vitae malesuada. Aliquam non diam risus.
        Sed at placerat nisl. Donec egestas erat at ornare scelerisque. Nam
        accumsan, metus nec feugiat porta, turpis libero commodo lorem, ac
        facilisis felis lectus nec ante. Fusce varius orci vitae lectus
        ultrices, vel placerat ante viverra. Aliquam quis elit quis nisl porta
        sodales. Fusce imperdiet eleifend arcu, a auctor massa laoreet eget.
        Duis tempus congue metus vitae lacinia. Nulla ultricies ultricies
        efficitur. Proin ac velit commodo, tincidunt sapien vitae, cursus eros.
        Cras sit amet mi sit amet dui posuere malesuada. Nullam aliquam massa
        vel magna ullamcorper, sed vestibulum nulla tempor. Maecenas et
        fermentum turpis. Etiam pharetra quam orci.
      </p>
    </div>
  );
};
